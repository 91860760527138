import { DateMode } from "@ero/app-common/enums/DateTimeMode";
import { AddOrderRequestBody } from "@ero/app-common/v2/routes/models/order";
import { ParcelsResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { getOrderNamePreset } from "@ero/app-common/v2/utils/prepopulateOrderName";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";
import { CloseButton } from "Components/closeButton/closeButton";
import { FullscreenButton } from "Components/fullScreenButton/FullscreenButton";
import { AppState } from "Store";
import { createOrder } from "Store/orders";
import { Form, Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DialogActionButtons } from "./components/dialogActionButtons";
import { DialogContents } from "./components/dialogContents";
import { StepperContent } from "./components/stepperContent";
import { ValuesType, initialValues, useValidationSchema } from "./formConfig";
import { Steps } from "./steps";

interface CreateOrderDialog {
  open: boolean;
  onClose: () => void;
}

const StyledDialog = styled(Dialog)(() => ({
  [".MuiDialog-paper"]: {
    height: "80vh",
    ["> .MuiPaper-root"]: {
      justifyContent: "space-between",
    },
  },
  [".MuiDialog-paperFullScreen"]: {
    height: "100vh",
  },
}));

export const CreateOrderDialog: React.FC<CreateOrderDialog> = ({
  open,
  onClose,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [activeStep, setActiveStep] = useState(Steps.GENERAL);
  const [stepsExtended, setStepsExtended] = useState(false);
  const [selectedParcels, setSelectedParcels] = useState<number[]>([]);
  const [selectedServices, setSelectedServices] = useState<number[]>([]);
  const [allParcelsOfCustomer, setAllParcelsOfCustomer] = useState<
    ParcelsResponseBody["data"]
  >([]);

  const { lastOrderId } = useSelector((state: AppState) => state.orders);

  const handleReset = useCallback(() => {
    setActiveStep(0);
    onClose();
  }, [onClose]);

  const preparedInitialValues: ValuesType = useMemo(
    () => ({
      ...initialValues,
      name: initialValues.name || getOrderNamePreset(lastOrderId),
    }),
    [lastOrderId],
  );

  const handleFormSubmit = useCallback(
    (values: ValuesType) => {
      const { dateRestrictions } = values;
      let dateMode = dateRestrictions.dateMode;
      let timeMode = dateRestrictions.timeMode;

      if (
        dateRestrictions.dateMode === DateMode.FIXEDDATE &&
        !dateRestrictions.date
      ) {
        dateMode = undefined;
        timeMode = undefined;
      }

      const preparedValues: AddOrderRequestBody = {
        name: values.name,
        customer: +values.customer,
        notes: values.notes,
        dateRestrictions: {
          ...dateRestrictions,
          dateMode,
          timeMode,
          dayTime: dateRestrictions.dayTime ?? {},
        },
        jobs: values.jobs
          .filter((entry) => entry !== undefined && entry.create)
          .map((job) => {
            if (job?.parcel && job.service)
              return {
                parcel: job?.parcel,
                service: job?.service,
              };
          }) as AddOrderRequestBody["jobs"],
      };

      dispatch(createOrder(preparedValues));
      handleReset();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const toggleFullscreen = useCallback(() => {
    setIsFullscreen((curr) => !curr);
  }, []);

  const validationSchema = useValidationSchema(stepsExtended);

  return (
    <StyledDialog
      maxWidth="lg"
      open={open}
      fullScreen={isFullscreen}
      fullWidth
      scroll="body"
    >
      <DialogTitle>{t("orders.create")}</DialogTitle>
      <FullscreenButton
        isFullscreen={isFullscreen}
        toggleFullscreen={toggleFullscreen}
      />
      <CloseButton onClose={handleReset} />
      <Formik
        validationSchema={validationSchema}
        initialValues={preparedInitialValues}
        onSubmit={handleFormSubmit}
      >
        {() => (
          <Form
            style={{
              height: "calc(100% - 64px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DialogContent
              dividers
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 0,
              }}
            >
              <Box p={1}>
                <StepperContent
                  activeStep={activeStep}
                  stepsExtended={stepsExtended}
                />
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  height: "calc(100% - 56px)",
                }}
              >
                <DialogContents
                  activeStep={activeStep}
                  selectedParcels={selectedParcels}
                  setSelectedParcels={setSelectedParcels}
                  selectedServices={selectedServices}
                  setSelectedServices={setSelectedServices}
                  isFullScreen={isFullscreen}
                  allParcelsOfCustomer={allParcelsOfCustomer}
                  setAllParcelsOfCustomer={setAllParcelsOfCustomer}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <DialogActionButtons
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                stepsExtended={stepsExtended}
                setStepsExtended={setStepsExtended}
                selectedParcels={selectedParcels}
                selectedServices={selectedServices}
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};
