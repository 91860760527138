import { JOBSTATUS } from "@ero/app-common/enums";
import { ParcelsResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { MapParcel } from "ProjectComponents/map/components";
import React, { useMemo } from "react";
import { markerIcons } from "Utils";

const showPolygonMaxZoom = 16;
interface IParcels {
  zoom?: number;
  parcels: ParcelsResponseBody["data"];
  itemOnClick?: (id: number) => void;
  selectedParcels: number[];
}

export const ParcelFigures: React.FC<IParcels> = ({
  zoom,
  parcels,
  itemOnClick,
  selectedParcels,
}) => {
  const showPolygon = useMemo(
    () => (zoom ? zoom > showPolygonMaxZoom : false),
    [zoom],
  );

  const parcelFigures = useMemo(
    () => (
      <>
        {parcels.map((parcel) => (
          <MapParcel
            key={parcel._id}
            showPolygon={showPolygon}
            parcel={parcel}
            itemOnClick={itemOnClick}
            markerProps={{
              customIcon: selectedParcels.includes(parcel._id)
                ? markerIcons[JOBSTATUS.DONE]
                : markerIcons[-1],
              zIndex: selectedParcels.includes(parcel._id) ? 1 : 0,
            }}
            fillColor={
              selectedParcels.includes(parcel._id) ? "#8efbcc" : "#fff"
            }
          />
        ))}
      </>
    ),
    [parcels, showPolygon, itemOnClick, selectedParcels],
  );

  return <>{parcelFigures}</>;
};
