import { OrderResponseBody } from "@ero/app-common/v2/routes/models/order";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OptionType } from "Types";

export type FilterOptions = {
  customers: OptionType[];
  services: OptionType[];
  crops: OptionType[];
  cropColors: OptionType[];
  regionettes: OptionType[];
  marks: OptionType[];
};

export const useFilterOptions = (
  orders: OrderResponseBody[],
): FilterOptions => {
  const [t] = useTranslation();

  const filterOptions = useMemo(() => {
    const rawFilterOptions = orders
      .map((order) =>
        order.jobDetails?.jobs.map((job) => {
          return {
            customer: order.customer,
            service: job.name,
            crop: job.parcel.crop,
            mark: job.parcel.mark,
            regionette: job.parcel.regionette,
          };
        }),
      )
      .filter((f) => f !== undefined)
      .flat();
    const customers = rawFilterOptions
      .map((f) => f.customer)
      .filter(
        (customer, index, customers) =>
          customers.findIndex((c) => c._id === customer._id) === index,
      )
      .map((customer) => ({
        label: customer.companyName,
        value: customer._id,
      }));
    const services = [...new Set(rawFilterOptions.map((f) => f.service))].map(
      (service) => ({ label: service, value: service }),
    );
    const crops = rawFilterOptions
      .map((f) => f.crop)
      .filter(
        (crop, index, crops) =>
          !isEmpty(crop) &&
          crops.findIndex((c) => c._id === crop._id) === index,
      )
      .map((crop) => ({ label: crop.name, value: crop._id }));
    const cropColors = rawFilterOptions
      .map((f) => f.crop)
      .filter(
        (crop, index, crops) =>
          crop.color !== undefined &&
          crops.findIndex((c) => c.color === crop.color) === index,
      )
      .map((crop) => ({
        label: t("parcels.kind." + crop.color),
        value: crop.color,
      }));
    const regionettes = [
      ...new Set<string>(
        rawFilterOptions
          .map((f) => f.regionette)
          .filter(
            (regionette: string | undefined) =>
              regionette !== undefined && regionette !== "",
          ) as string[],
      ),
    ].map((regionette: string) => ({
      label: regionette,
      value: regionette,
    }));
    const marks = [
      ...new Set<string>(
        rawFilterOptions
          .map((f) => f.mark)
          .filter(
            (mark: string | undefined) => mark !== undefined && mark !== "",
          ) as string[],
      ),
    ].map((mark: string) => ({
      label: mark,
      value: mark,
    }));
    return { customers, services, crops, regionettes, marks, cropColors };
  }, [orders, t]);
  return filterOptions;
};
