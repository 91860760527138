import { BILLINGUNIT } from "@ero/app-common/enums";
import {
  convertAreaToCustom,
  UnitSymbol,
} from "@ero/app-common/util/convertArea";
import { Milliseconds } from "@ero/app-common/util/Milliseconds";
import { numberFormatter } from "@ero/app-common/util/numberFormatter";
import { type SqMeter, type UNIT } from "@ero/app-common/util/Units";
import dayjs from "dayjs";
import i18n from "i18next";
import storage from "redux-persist/lib/storage";
import { type GetTableDataParams } from "Types";

export const displaySizeOrFallback = (
  size: SqMeter | null | undefined,
  displayUnit: UNIT,
  fallback: string | (() => string),
  showUnit: boolean = true,
): string => {
  const symbol = UnitSymbol[displayUnit];
  if (size === undefined || size === null || size === 0) {
    return typeof fallback === "string"
      ? fallback + " " + (showUnit ? symbol : "")
      : fallback();
  }

  const custom = convertAreaToCustom(size, displayUnit);
  const formatted = numberFormatter(custom);

  return [formatted, showUnit ? symbol : ""].join("");
};

export const resizeTo = (file: File, max: number): Promise<string> => {
  return new Promise((resolve) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);

    fileReader.addEventListener("load", () => {
      let encodedUpload = fileReader.result;

      const img = document.createElement("img") as any;

      let width;

      let height;

      img.addEventListener("load", () => {
        const canvas = document.createElement("canvas");

        const ctx = canvas.getContext("2d");

        width = img.width;
        height = img.height;

        if (width > max) {
          const coefficient = max / width;
          width = max;
          height = Math.floor(height * coefficient);
        }

        if (height > max) {
          const coefficient = max / height;
          height = max;
          width = Math.floor(width * coefficient);
        }

        canvas.width = width;
        canvas.height = height;

        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height);
          encodedUpload = canvas.toDataURL("image/png");
          canvas.remove();
          img.remove();
          resolve(encodedUpload);
        }
      });

      img.src = encodedUpload;
    });
  });
};

export const file2base64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", function () {
      if (typeof this.result === "string") {
        resolve(this.result);
      } else {
        reject();
      }
    });
    reader.addEventListener("error", reject);
    reader.readAsDataURL(file);
  });

export function date2inputValue(date: Date) {
  if (!date) {
    date = new Date();
  }

  return (
    [
      date.getFullYear(),
      (date.getMonth() + 1).toString().padStart(2, "0"),
      date.getDate().toString().padStart(2, "0"),
    ].join("-") +
    "T" +
    [date.getHours(), date.getMinutes(), date.getSeconds()]
      .map((it) => it.toString().padStart(2, "0"))
      .join(":")
  );
}

export function downloadFile(file: string, fileName: string) {
  const link = document.createElement("a");
  link.href = file;
  link.download = fileName;
  link.click();
}

export const downloadCsvFile = (csvString: string, fileName: string) => {
  const link = document.createElement("a");
  const blob = new Blob([csvString], { type: "text/csv" });
  link.href = URL.createObjectURL(blob);
  link.download = `${fileName}.csv`;
  link.click();
};

export function isAllDay(start: Date, end: Date): boolean {
  return (
    // 24 hours in milliseconds minus 1 minute or more to be considered a full day
    end.getTime() - start.getTime() >= 1000 * 60 * 60 * 24 - 1000 * 60 &&
    start.getHours() === 0
  );
}

export const getBillingUnitNameByValue = (
  billingUnit: BILLINGUNIT,
  unitOfMeasurement: UNIT,
) => {
  const billingUnits = {
    [BILLINGUNIT.NONE]: i18n.t("billing_unit.none"),
    [BILLINGUNIT.METERHARVESTED]: i18n.t("billing_unit.meter_harvested"),
    [BILLINGUNIT.METERSHAKED]: i18n.t("billing_unit.meter_shaked"),
    [BILLINGUNIT.TIMEHARVESTED]: i18n.t("billing_unit.time_harvested"),
    [BILLINGUNIT.TIMESHAKED]: i18n.t("billing_unit.time_shaked"),
    [BILLINGUNIT.AREA]: i18n.t("billing_unit.area", {
      unit: UnitSymbol[unitOfMeasurement],
    }),
    [BILLINGUNIT.FIXEDRATE]: i18n.t("billing_unit.fixed_rate"),
  };

  return billingUnits[billingUnit];
};

function addLeadingZero(number) {
  return number < 10 ? `0${number}` : number.toString();
}

export function millisecondsToTime(
  timestamp: Milliseconds,
  omitSeconds = false,
): string {
  const totalSeconds = Math.floor(timestamp / 1000);

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${addLeadingZero(hours)}:${addLeadingZero(minutes)}${
    !omitSeconds ? `:${addLeadingZero(seconds)}` : ""
  }`;
}

export const getTablePersistConfig = (
  key: string,
  additionalWhiteList?: string[],
) => ({
  key,
  storage,
  whitelist: [
    "hiddenColumns",
    "searchData",
    "filters",
    "columnsOrder",
    ...(additionalWhiteList || []),
  ],
});

export const getTableParamsAfterDeletion = (
  params: GetTableDataParams,
  listLength: number,
  deletedLength: number,
) => {
  if (deletedLength >= listLength && params.page > 0) {
    return {
      ...params,
      page: params.page - 1,
    };
  }

  return params;
};

export const isTargetOverDiv = (
  { x, y }: { x: number; y: number },
  div: HTMLDivElement,
) => {
  const divReact = div.getBoundingClientRect();
  if (
    x >= divReact.left &&
    x <= divReact.right &&
    y >= divReact.top &&
    y <= divReact.bottom
  ) {
    return true;
  }
  return false;
};

export const getInitialDateRange = (view: string, date?: number) => {
  let unitType: dayjs.OpUnitType = "day";

  if (view?.toLowerCase().includes("week")) {
    unitType = "week";
  }

  if (view?.toLowerCase().includes("month")) {
    unitType = "month";
  }

  return {
    start: dayjs(date || Date.now())
      .startOf(unitType)
      .valueOf(),
    end: dayjs(date || Date.now())
      .endOf(unitType)
      .valueOf(),
  };
};

export function offset2pagination(
  offset: number,
  pageLength: number = 20,
): { page: number; pageLength: number } {
  return { page: Math.floor(offset / pageLength), pageLength };
}

export const uniqByKey = (a: object[], key: string) => {
  return [...new Map(a.map((x) => [x[key], x])).values()];
};
