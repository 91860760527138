import { LANGUAGE } from "@ero/app-common/enums/language";
import {
  convertAreaToCustom,
  UnitSymbol,
} from "@ero/app-common/util/convertArea";
import { type Milliseconds } from "@ero/app-common/util/Milliseconds";
import { numberFormatter } from "@ero/app-common/util/numberFormatter";
import { UNIT } from "@ero/app-common/util/Units";
import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { EventApi } from "@fullcalendar/core";
import { Box, LinearProgress, Typography, useTheme } from "@mui/material";
import { CalendarEventColor } from "Enums";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { updateJobLock } from "Store/planning";
import { type CalendarEventInput } from "Types";
import { getJobColor, getJobTextColor } from "Utils";
import { JobIcons } from "../components";
import { MarkedEventType } from "../helpers";

interface IUseJobs {
  calendarCurrentView: string;
  emptyMarkedEvent: MarkedEventType;
  eventColor: CalendarEventColor;
  jobs: JobResponseBody[];
  markedEvent: MarkedEventType;
  setMarkedEvent: React.Dispatch<React.SetStateAction<MarkedEventType>>;
  unitOfMeasurement: UNIT;
  updateJobLoading: boolean;
}

export const useJobs = (props: IUseJobs) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    calendarCurrentView,
    emptyMarkedEvent,
    eventColor,
    jobs,
    markedEvent,
    setMarkedEvent,
    unitOfMeasurement,
    updateJobLoading,
  } = props;

  const jobEvents = useMemo(
    () =>
      jobs.reduce((acc, job) => {
        // Fix needed, when we add functionality for multiple employees
        const resourceId = job.employees.length
          ? `${job.employees[0]._id}`
          : "0";

        const isInMarkedRange =
          !job.locked &&
          Number(job.start) < markedEvent.dayRange.end &&
          Number(job.start) >= markedEvent.dayRange.start &&
          markedEvent.selectedResource === resourceId;

        const color = getJobColor(
          eventColor,
          job.status,
          job.parcel.crop.color,
          theme,
        );

        acc.push({
          id: `${job._id}`,
          start: new Date(Number(job.start)),
          end: new Date(Number(job.end)),
          title: job.name,
          originalItem: job,
          resourceId,
          backgroundColor: color,
          borderColor: color,
          textColor: getJobTextColor(color, theme),
          groupId: isInMarkedRange ? "marked" : `${job._id}`,
          durationEditable: !isInMarkedRange && !job.locked,
          editable: !job.locked,
          resourceEditable: !job.locked,
          overlap: resourceId === "0",
        });
        return acc;
      }, [] as CalendarEventInput[]),
    [jobs, eventColor, markedEvent, theme],
  );

  const eventsInMarkedRange = jobEvents
    .filter((jE) => jE.groupId === "marked")
    .map((jE) => jE.id);

  const importantIconOnClick = useCallback(
    (
      id: number,
      jobStart: Milliseconds,
      jobEnd: Milliseconds,
      selectedResource: string,
    ) => {
      if (markedEvent.id === id) {
        setMarkedEvent(emptyMarkedEvent);
      } else {
        const end = +moment(jobEnd).endOf("day").format("x") as Milliseconds;
        setMarkedEvent({
          id,
          dayRange: { start: jobStart, end },
          selectedResource,
        });
      }
    },
    [emptyMarkedEvent, markedEvent.id, setMarkedEvent],
  );

  const updateJobLockHandler = useCallback((id: number, locked: boolean) => {
    dispatch(updateJobLock({ data: { id, locked } }));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderJobEventContent = useCallback(
    (job: JobResponseBody, event: EventApi) => (
      <>
        {updateJobLoading && <LinearProgress />}
        <Box
          lang={LANGUAGE.DE}
          style={{
            width: "85%",
            overflowWrap: "break-word",
            hyphens: "auto",
          }}
        >
          <Typography variant="subtitle2">{job.name} </Typography>
          {job.customer?.companyName && (
            <Typography variant="body2">{job.customer?.companyName}</Typography>
          )}
          {job.parcel.name && (
            <Typography variant="caption">{job.parcel.name}</Typography>
          )}
        </Box>
        {Number(job.parcel?.size) > 0 && (
          <Typography variant="caption">
            {numberFormatter(
              convertAreaToCustom(job.parcel?.size, unitOfMeasurement),
            )}
            {UnitSymbol[unitOfMeasurement]}
          </Typography>
        )}
        {job.parcel.crop.name && (
          <Typography variant="caption">{job.parcel.crop.name}</Typography>
        )}
        <br />
        {job.notes && (
          <Typography variant="caption">{job.notes}</Typography>
        )}{" "}
        <JobIcons
          job={job}
          calendarEvent={event}
          markedEvent={markedEvent}
          eventsInMarkedRange={eventsInMarkedRange}
          importantIconOnClick={importantIconOnClick}
          updateJobLockHandler={updateJobLockHandler}
          calendarViewType={calendarCurrentView}
        />
      </>
    ),
    [
      markedEvent,
      eventsInMarkedRange,
      calendarCurrentView,
      importantIconOnClick,
      unitOfMeasurement,
      updateJobLoading,
      updateJobLockHandler,
    ],
  );

  return { jobEvents, renderJobEventContent };
};
